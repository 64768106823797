<template>
  <div class="ma-4">
    <v-toolbar class="mb-4">
      <v-toolbar-title>Capstone Home</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col v-if="hasCapstone" :cols="12" md="6">
        <p v-if="majors.length >= 1">You are (or have been) registered for a capstone course, so you can upload your Capstone to the archives. Select the entry below to upload your Capstone for the seleced major.</p>
        <v-list>
          <v-list-item v-for="{ major, id, data } in majors" :key="major" :href="id ? 'mailto:kimberly.crocker@covenant.edu' : ''" :target="id ? '_blank' : ''" @click="id ? '' : openDialog({ major, data })">
            <v-list-item-content>
              <v-list-item-title>{{ major }} Capstone</v-list-item-title>
              <v-list-item-subtitle v-if="!id">Click here to upload your {{ major }} Capstone</v-list-item-subtitle>
              <v-list-item-subtitle v-else style="white-space:normal">Your {{ major }} Capstone has been uploaded. If you need to make changes, email kimberly.crocker@covenant.edu.</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="id ? 'success' : 'info'">fas fa-{{ id ? 'check' : 'exclamation' }}-circle</v-icon>
                </template>
                <span>Your {{ major }} Capstone {{ id ? 'has' : 'has not yet' }} been uploaded. {{ id ? 'Click here to update it.' : 'Click here to upload it.' }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-dialog v-model="dialogOpen" width="500">
          <v-card>
            <v-card-title>{{ capstoneId ? 'Update' : 'Upload' }} {{ capstoneMajor }} Capstone</v-card-title>
            <v-card-text>
              <p>All fields must be completed to upload your Capstone.</p>
              <v-text-field v-model="title" label="Title" outlined></v-text-field>
              <v-select v-model="permission" :items="permissionOptions" label="Who should be able to see your Capstone?" outlined hide-details></v-select>
              <p class="mt-2">Please enter some keywords below for your Capstone. This could be the main themes or topics or the primary idea behind the Capstone. You need to include at least 3 keywords.</p>
              <v-text-field v-model="keywords" label="Keywords" outlined></v-text-field>
              <v-file-input v-model="file" :label="(capstoneId ? 'Update' : 'Upload') + ' your Capstone.'" accept="application/pdf" hint="Must be a PDF" persistent-hint outlined></v-file-input>
              <v-checkbox v-model="copyright" label="The work contained in this document is either the original work of the author, or properly cited work by other writers." hide-details></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="formIsInvalid" color="success" @click="uploadCapstone()">Upload Capstone</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="dialogOpen = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn class="my-4" href="https://portal.covenant.edu/media/Capstone Archives User Guide_Updated.pdf" target="_blank" color="blue" text>Capstone Archives User Guide</v-btn>
        <div v-if="evalOpen">
          <v-divider />
          <p class="mt-4">You can now complete your Capstone Course Evaluation. Please click the link below for each capstone course for which you were registered this term and complete a quick evaluation. Thanks.</p>
          <v-list>
            <v-list-item v-for="{ id, course, courseTitle } in capstoneCourses" :key="course" @click="id ? '' : openEval(course)">
              <v-list-item-content>
                <v-list-item-title>{{ courseTitle }}</v-list-item-title>
                <v-list-item-subtitle v-if="!id">Click here to complete the course evaluation for {{ course }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else style="white-space:normal">You have completed this course evaluation.</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :color="id ? 'success' : 'info'">fas fa-{{ id ? 'check' : 'exclamation' }}-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
      <v-col>
        <v-card v-if="hasCapstone" class="mb-4">
          <v-card-title>Capstone Carrel</v-card-title>
          <v-alert v-if="selectedCarrel" type="info" class="mb-0">
            You are currently signed up for carrel {{ selectedCarrel }} for this term.
          </v-alert>
          <v-card-actions>
            <v-btn to="/library/capstone-carrel" outlined>{{ selectedCarrel ? 'Manage your Carrel reservation' : 'Reserve a Capstone Carrel' }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-btn :to="'/library/archives/Capstone'">Search the Capstone archive</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { uploadFile } from '@/helpers/functions'
import { courseList } from '@/components/student/capstone'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const hasCapstone = ref(false)
    const majors = ref([])
    const year = ref('')

    onMounted(async () => {
      // Get the current term
      const { data: termData } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', start: { $lte: new Date() }, $sort: { start: -1 } } })
      if ('capstoneCourseEvals' in termData[0]) {
        const open = new Date(termData[0].capstoneCourseEvals.open)
        const close = new Date(termData[0].capstoneCourseEvals.close)
        const now = new Date()
        if (open < now && close > now) evalOpen.value = true
      }
      const { data: optionData } = await root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } })
      const { value } = optionData[0]
      const { options: majorOptions } = await root.$feathers.service('forms/select-options').get(value)
      const aggregate = [
        { $match: { pidm: user.value.pidm } },
        { $unwind: '$schedule' },
        { $project: {
          term: 1,
          major: '$academics.major',
          course: {
            $substr: ['$schedule.title', 0, 6]
          },
          courseTitle: '$schedule.title',
          year: { $cond: { if: { $gt: [{ $month: '$academics.expGradDate' }, 7] }, then: { $add: [{ $year: '$academics.expGradDate' }, 1] }, else: { $year: '$academics.expGradDate' } } }
        } },
        { $match: {
          course: {
            $in: courseList
          }
        } },
        { $sort: { term: -1 } }
      ]
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      if (data.length > 0) {
        hasCapstone.value = true
        capstoneCourses.value = data.filter(({ term: t }) => t === termData[0].term)
        majors.value = majorOptions.filter(({ value }) => data[0].major.includes(value)).map(({ text, value }) => { return { major: text, id: null, dialogOpen: false } })
        if (majors.value.length === 0) {
          majors.value = majorOptions.filter(({ value }) => data[0].major.map((val) => val.substring(0, 3)).includes(value)).map(({ text, value }) => { return { major: text, id: null, dialogOpen: false } })
        }
        year.value = data[0].year

        // Look to see if they have already uploaded a Capstone
        const { data: entryData } = await root.$feathers.service('library/archive/entry').find({ query: { category: 'Capstone', author: user.value.name, year: year.value } })
        for (const row of entryData) {
          const { _id, major } = row
          for (let i = 0; i < majors.value.length; i++) {
            if (major.includes(majors.value[i].major)) {
              majors.value.splice(i, 1, { ...majors.value[i], id: _id, data: row })
              break
            }
          }
        }
      }
    })

    const dialogOpen = ref(false)
    const capstoneMajor = ref('')
    const capstoneId = ref(null)
    const title = ref('')
    const file = ref('')
    const permission = ref('')
    const permissionOptions = ref([{ text: 'Everyone at Covenant', value: 'Campus' }, { text: 'Internal Use Only (Covenant Employees only)', value: 'Restricted' }])
    const keywords = ref('')
    const copyright = ref(false)

    function openDialog ({ major, id, data }) {
      capstoneMajor.value = major
      capstoneId.value = id
      if (data == null) {
        title.value = ''
        file.value = ''
        permission.value = ''
        keywords.value = ''
      } else {
        title.value = data.title
        permission.value = data.permission
        const temp = data.text.split('--')
        if (temp.length > 2) keywords.value = temp[2].trim()
        else keywords.value = ''
      }
      dialogOpen.value = true
    }

    async function uploadCapstone () {
      if (capstoneId.value == null) {
        // Get the folder
        const { data } = await root.$feathers.service('library/archive/drive-file').find({ query: { type: 'Capstone', permission: permission.value, year: year.value } })
        if (data.length > 0) {
          const folder = data[0].id
          let filename = 'SIP - ' + year.value + ' - ' + capstoneMajor.value + ' - ' + user.value.name
          const obj = {
            category: 'Capstone',
            subcategory: year.value + ' ' + capstoneMajor.value,
            title: title.value,
            date: new Date(),
            author: user.value.name,
            permission: permission.value,
            year: year.value,
            major: [capstoneMajor.value],
            text: title.value + ' -- ' + user.value.name + ' -- ' + keywords.value
          }
          try {
            obj.fileLink = await uploadFile({ file: file.value, filename, folder, extraFormData: { category: 'Capstone', author: user.value.name } }, 'library/archive/drive-file', true)
            console.log(obj)
            const { _id } = await root.$feathers.service('library/archive/entry').create(obj)
            for (let i = 0; i < majors.value.length; i++) {
              if (majors.value[i].major === capstoneMajor.value) {
                majors.value.splice(i, 1, { ...majors.value[i], id: _id })
              }
            }
            root.$store.dispatch('main/snackbar', { color: 'success', text: 'Capstone uploaded successfully' })
            dialogOpen.value = false
          } catch (e) {
            console.error(e)
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error uploading Capstone: ' + e })
          }
        }
      } else {
        // Check to see if they are uploading a new file; if so, we need to replace the previous file
        // Check to see if the permissions changed; if so, we need to move the file to the appropriate folder
      }
    }

    const formIsInvalid = computed(() => !title.value || !permission.value || !keywords.value || keywords.value.match(/\S+/g).length < 3 || !file.value || !copyright.value)

    const selectedCarrel = ref(null)
    const selectedCarrelId = ref(null)
    const selectedCarrelTerm = ref(null)

    onMounted(async () => {
      const endDate = new Date()
      endDate.setDate(endDate.getDate() - 30)
      const { data: termData } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', end: { $gte: endDate }, $sort: { term: 1 }, $limit: 1, $select: ['term'] } })
      const term = (termData.length > 0 ? termData[0].term : null)
      const { total, data } = await root.$feathers.service('library/carrel').find({ query: { term, 'slots.directoryId': user.value.directoryId } })
      if (total > 0) {
        selectedCarrel.value = data[0].carrel
        selectedCarrelId.value = data[0]._id
        selectedCarrelTerm.value = data[0].term
      }
    })

    const capstoneCourses = ref([])
    const evalOpen = ref(false)
    function openEval (course) {
      const rec = capstoneCourses.value.find(({ course: c }) => course === c)
      // Look up their supervisor (would this be the same as the course instructor? Or is it something different?)
      // Load the capstone evaluation form with the course information
      console.log(rec)
    }

    return {
      hasCapstone,
      majors,
      dialogOpen,
      capstoneMajor,
      capstoneId,
      title,
      file,
      permission,
      permissionOptions,
      keywords,
      copyright,
      openDialog,
      uploadCapstone,
      formIsInvalid,
      selectedCarrel,
      selectedCarrelId,
      capstoneCourses,
      evalOpen,
      openEval
    }
  }
}
</script>
